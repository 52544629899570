import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';

import AudioComponent from '../../../../components/AudioComponent/AudioComponent';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import { removeHtmlTag, getBreadCrumb } from '../../../../Utils/Utils';
import Layout from '../../../../Layout';
import PageBanner from '../../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../../components/Seo/Seo';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import VisuelTextOverlappedBlock from '../../../../components/ContentType/VisuelTextOverlappedBlock/VisuelTextOverlappedBlock';
import useMedias from '../../../../hooks/useMedias';

import './styles.scss';

let classNames = require('classnames');

const OurStrategyPage = ({ data }) => {
  const intl = useIntl();
  const { getImage } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const ourStrategyData = data?.ourStrategyData?.edges[0]?.node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, ourStrategyData.path?.alias);
  const metaTags =  ourStrategyData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
  metaTags.forEach((meta) => {
    if(meta.attributes.name === 'title'){
        metaTitle = meta.attributes.content;
    }
    if(meta.attributes.name === 'description'){
        metaDesc = meta.attributes.content;
    }
  });

  const [innerNavList, setInnerNavList] = useState([]);
  const [allText, setAllText] = useState('');

  let globalText = '';
  let innerNav = [];

  useEffect(() => {
    setInnerNavList(innerNav);
    setAllText(
      removeHtmlTag(
        `${ourStrategyData.title}.${ourStrategyData.field_sous_titre !== null &&
          ourStrategyData.field_sous_titre !== undefined
          ? ourStrategyData.field_sous_titre
          : ''
        }.${globalText}`
      )
    );
  }, []);

  return (
    <Layout>
      <Seo
        title={ourStrategyData?.field_metatag?.title ? ourStrategyData?.field_metatag?.title : metaTitle}
        description={ourStrategyData?.field_metatag?.description ? ourStrategyData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'our_strategy_page')}>
        <ScrollPageNav data={innerNavList} />
        <PageBanner
          visuel={getImage(
            imagesArray,
            ourStrategyData?.relationships?.field_image?.drupal_internal__mid
          )}
          scroll={true}
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                // grandParentPage: { title: 'Acceuil', url: '/' },
                parentPage: {
                  title: breadCrumb?.parent?.name,
                  url: breadCrumb?.parent?.link,
                },
                currentPage: {
                  title: breadCrumb?.current?.name,
                  url: breadCrumb?.current?.link,
                },
                locale: ourStrategyData.langcode,
              }}
            />

            <div
              className={classNames({
                title_audio:
                  allText &&
                  ourStrategyData.field_version_audio &&
                  allText.length > 30,
              })}
            >
              {' '}
              {/* !!!!!! if audio add title_autio if not put nothing */}
              <TitlePage
                color="color_white"
                title={ourStrategyData?.title}
                description={ourStrategyData?.body?.processed}
              />
              {allText &&
                ourStrategyData.field_version_audio &&
                allText.length > 30 && (
                  <AudioComponent
                    text={intl.formatMessage({
                      id: 'detailsActivities.button.listen.label',
                    })}
                    textToRead={allText}
                    color="color_white"
                  />
                )}
            </div>
          </div>
        </PageBanner>

        {ourStrategyData.relationships?.field_blocs?.map((block, i) => {
          globalText += `${block.field_title?.processed != undefined &&
            block.field_title?.processed != null
            ? block.field_title?.processed
            : ''
            }.${block.field_subtitle?.processed != undefined &&
              block.field_subtitle?.processed != null
              ? block.field_subtitle?.processed
              : ''
            }.${block.body?.processed != undefined && block.body?.processed != null
              ? block.body?.processed
              : ''
            }`;
          if (block.__typename === 'block_content__block_texte') {
            innerNav.push({
              title: block.field_title?.processed,
              section: 'scroll_to_' + i,
            });

            switch (block.field_backgroud_color) {
              case 'white':
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'scroll_to_' + i,
                      'section_developement'
                    )}
                    key={i}
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block.field_title?.processed}
                        description={removeHtmlTag(
                          block.field_subtitle?.processed
                        )}
                        type="line"
                      />
                      {block.relationships?.field_section?.map((section, j) => {
                        globalText += `${section.relationships?.field_bloc_texte[0]
                          ?.field_title?.processed != undefined
                          ? section.relationships?.field_bloc_texte[0]
                            ?.field_title?.processed
                          : ''
                          }.${section.relationships?.field_bloc_texte[0]
                            ?.field_bloc_text_1.processed
                          }`;
                        return (
                          <TextVisuelTwoCol
                            key={j}
                            orderInverse={
                              section?.field_alignment === 'right'
                                ? true
                                : false
                            }
                            extremeLeft={
                              section?.field_alignment === 'left' &&
                                section?.field_no_padding
                                ? true
                                : false
                            }
                            extremeRight={
                              section?.field_alignment === 'right' &&
                                section?.field_no_padding
                                ? true
                                : false
                            }
                            visuel={getImage(
                              imagesArray,
                              section?.relationships?.field_image
                                ?.drupal_internal__mid
                            )}
                            title={
                              section.relationships?.field_bloc_texte[0]
                                ?.field_title?.processed
                            }
                            text={
                              section.relationships?.field_bloc_texte[0]
                                ?.field_bloc_text_1?.processed
                            }
                          />
                        );
                      })}
                    </div>
                  </section>
                );

              case 'ice':
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'scroll_to_' + i,
                      'section_climate_issues'
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top="left"
                      color="catskill_white"
                      bottomInfinite={true}
                    >
                      <div className="full_page_wrapper">
                        <div className="wrapper_page_xs">
                          <TitleSection
                            title={block.field_title?.processed}
                            description={removeHtmlTag(
                              block.field_subtitle?.processed != undefined
                                ? block.field_subtitle?.processed
                                : ''
                            )}
                            type="arrow"
                            h2color="color_dark_bleu"
                          />
                        </div>
                        <VisuelTextOverlappedBlock
                          extremeLeft={true}
                          visuel={getImage(
                            imagesArray,
                            block?.relationships?.field_image
                              ?.drupal_internal__mid
                          )}
                          bgColor="color_dark_bleu"
                          alt=""
                          text={block?.body?.processed}
                        />
                        <div className="wrapper_page_xs">
                          <div
                            className="free_highlight_text"
                            dangerouslySetInnerHTML={{
                              __html: block?.field_bloc_text_1?.processed,
                            }}
                          ></div>
                          {block.relationships?.field_section?.map(
                            (section, j) => {
                              globalText += `${section.relationships?.field_bloc_texte[0]
                                ?.field_title?.processed != undefined
                                ? section.relationships?.field_bloc_texte[0]
                                  ?.field_title?.processed
                                : ''
                                }.${section.relationships?.field_bloc_texte[0]
                                  ?.field_bloc_text_1.processed
                                }`;
                              return (
                                <TextVisuelTwoCol
                                  key={j}
                                  orderInverse={
                                    section?.field_alignment === 'right'
                                      ? true
                                      : false
                                  }
                                  extremeLeft={
                                    section?.field_alignment === 'left' &&
                                      section?.field_no_padding
                                      ? true
                                      : false
                                  }
                                  extremeRight={
                                    section?.field_alignment === 'right' &&
                                      section?.field_no_padding
                                      ? true
                                      : false
                                  }
                                  visuel={getImage(
                                    imagesArray,
                                    section?.relationships?.field_image
                                      ?.drupal_internal__mid
                                  )}
                                  title={
                                    section.relationships?.field_bloc_texte[0]
                                      ?.field_title?.processed
                                  }
                                  text={
                                    section.relationships?.field_bloc_texte[0]
                                      ?.field_bloc_text_1?.processed
                                  }
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'navy':
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'scroll_to_' + i,
                      'section_force_astuce'
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top="left"
                      bottomInfinite={true}
                      color="dark_bleu"
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          description={
                            block.field_subtitle?.processed
                              ? removeHtmlTag(block.field_subtitle?.processed)
                              : null
                          }
                          type="arrow"
                          h2color="white"
                        />
                      </div>
                      <VisuelTextOverlappedBlock
                        visuel={getImage(
                          imagesArray,
                          block?.relationships?.field_image
                            ?.drupal_internal__mid
                        )}
                        bgColor="color_sky_bleu"
                        extremeLeft={true}
                        alt=""
                        text={block?.body?.processed}
                      />
                      <div className="wrapper_page_xs">
                        {block.relationships?.field_section?.map(
                          (section, j) => {
                            globalText += `${section.relationships?.field_bloc_texte[0]
                              ?.field_title?.processed != undefined
                              ? section.relationships?.field_bloc_texte[0]
                                ?.field_title?.processed
                              : ''
                              }.${section.relationships?.field_bloc_texte[0]
                                ?.field_bloc_text_1.processed
                              }`;
                            return (
                              <TextVisuelTwoCol
                                key={j}
                                orderInverse={
                                  section?.field_alignment === 'right'
                                    ? true
                                    : false
                                }
                                extremeLeft={
                                  section?.field_alignment === 'left' &&
                                    section?.field_no_padding
                                    ? true
                                    : false
                                }
                                extremeRight={
                                  section?.field_alignment === 'right' &&
                                    section?.field_no_padding
                                    ? true
                                    : false
                                }
                                visuel={getImage(
                                  imagesArray,
                                  section?.relationships?.field_image
                                    ?.drupal_internal__mid
                                )}
                                colorTitle="color_white"
                                colorDescription="color_white"
                                title={
                                  section.relationships?.field_bloc_texte[0]
                                    ?.field_title?.processed
                                }
                                titleBackground={true}
                                alignTop={true}
                                text={
                                  section.relationships?.field_bloc_texte[0]
                                    ?.field_bloc_text_1?.processed
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    </ShapeBackground>
                  </section>
                );
            }
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query OurStrategyTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    ourStrategyData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          langcode
          field_version_audio
          path {
            alias
            langcode
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          field_type
          relationships {
            field_image {
              drupal_internal__mid
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_texte {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_section {
                      field_no_padding
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                        field_bloc_texte {
                          field_title {
                            processed
                          }
                          field_bloc_text_1 {
                            processed
                          }
                        }
                      }
                      field_alignment
                    }
                  }
                  body {
                    processed
                  }
                  field_backgroud_color
                }
              }
            }
          }
          langcode
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default OurStrategyPage;
