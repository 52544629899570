import * as React from 'react';

import PropTypes from 'prop-types';

import useMedias from '../../../hooks/useMedias';
import { Button } from '../../../Molecules/Button/Button';
import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';

import './TextVisuelTwoCol.scss';
let classNames = require('classnames');

const TextVisuelTwoCol = ({
  extremeLeft,
  extremeRight,
  visuel,
  alt,
  colorTitle,
  colorDescription,
  title,
  text,
  textList,
  titleBackground,
  titleHash,
  alignTop,
  orderInverse,
  cta,
}) => {
  const { processUrl } = useMedias();
  const { ctaText, ctaLink, ctaTarget } = cta || {};

  return (
    <div className={classNames('text_visuel_two_col')}>
      <div
        className={classNames({
          cross_wrapper_extreme_right: extremeRight,
          cross_wrapper_extreme_left: extremeLeft,
        })}
      >
        <div
          className={classNames(
            'text_visuel',
            { order_inverse: orderInverse },
            { align_top: textList || alignTop }
          )}
        >
          <div className={classNames('visuel')}>
            {visuel && (
              <img
                src={processUrl(visuel.uri.url)}
                alt={alt}
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              />
            )}
          </div>
          <div className={classNames('text_container')}>
            <div
              className={classNames('text_center', {
                no_title: !title && !titleHash,
              })}
            >
              {title && !titleHash && (
                <TitleBlock
                  title={title}
                  titleBackground={titleBackground}
                  color={colorTitle}
                />
              )}
              {titleHash && <div className="title_hash">{title}</div>}
              {text && (
                <div
                  className={colorDescription ? colorDescription : ''}
                  dangerouslySetInnerHTML={{ __html: text }}
                ></div>
              )}
              {textList && (
                <ul className={colorDescription ? colorDescription : ''}>
                  {textList.map((textItem, index) => (
                    <li key={index}>{textItem.text}</li>
                  ))}
                </ul>
              )}
              {ctaText && ctaLink && (
                <Button
                  label={ctaText}
                  link={ctaLink}
                  target={ctaTarget ? '_blank' : '_self'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TextVisuelTwoCol.defaultProps = {
  text: '',
  alt: '',
  text_color: 'color_dark_bleu',
  titleBackground: false,
};

TextVisuelTwoCol.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  extremeRight: PropTypes.bool,
  extremeLeft: PropTypes.bool,
  cta: PropTypes.object,
};

export default TextVisuelTwoCol;
